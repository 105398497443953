import React from "react";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";
import "./CardSummaryAboutUs.css";

export default function CardSummaryAboutUs({
  imagen,
  titulo,
  texto,
  botonTexto,
  index,
  link,
}) {
  const isEven = index % 2 === 0;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <div className="grid grid-nogutter col-12 justify-content-center align-items-center mt-4 mb-4 p-0">
      <div
        className={classNames(
          "flex flex-column w-full md:w-10 md:flex-row justify-content-center align-items-center gap-3 mb-4 mt-4",
          {
            "md:flex-row-reverse": !isEven,
          }
        )}
        data-aos={isEven ? "fade-left" : "fade-right"}
      >
        <div className="w-full md:w-3 md:text-left">
          <h1 className="raleway-bold text-4xl m-0">{titulo}</h1>
          <p className="raleway-regular text-sm">{texto}</p>
          <Button
            label={botonTexto}
            icon="pi pi-arrow-right"
            iconPos="right"
            className="raleway-medium custom-btn-light"
            size="small"
            onClick={handleClick}
          />
        </div>
        <div
          className={classNames("w-full image-container6", {
            "image-custom1 border-1 border-solid": isEven,
            "image-custom2 border-1 border-solid": !isEven,
          })}
        >
          <img src={imagen} alt={titulo} className="image-custom-base" />
        </div>
      </div>
    </div>
  );
}
