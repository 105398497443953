import React, { useState } from "react";
import "./FilterToolbarProyects.css";
import { SelectButton } from "primereact/selectbutton";

export default function FilterToolbarProyects({ onFilterChange }) {
  const [value, setValue] = useState(null);

  const items = [
    { name: "House", value: 1 },
    { name: "Spaces", value: 2 },
    { name: "Master Planning", value: 3 },
  ];

  const itemTemplate = (option) => {
    return (
      <div
        className="w-full h-3rem raleway-medium text-sm flex flex-wrap justify-content-center align-content-center"
        data-aos="fade-up"
      >
        {option.name}
      </div>
    );
  };

  const handleChange = (e) => {
    setValue(e.value);
    onFilterChange(e.value); // Notifica al componente padre sobre el cambio de filtro
  };

  return (
    <div className="FilterToolbarProyects">
      <SelectButton
        value={value}
        onChange={handleChange}
        optionLabel="name"
        options={items}
        itemTemplate={itemTemplate}
        className="grid grid-nogutter h-5rem justify-content-center align-content-center"
      />
    </div>
  );
}
