import React from "react";
import "./Map.css";

export default function Map() {
  return (
    <div className="grid grid-nogutter justify-content-center align-content-center">
      <div className="flex flex-column w-10 md:w-8 justify-content-center align-items-center gap-4 mb-4">
        <div className="flex justify-content-start w-full h-25rem map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.641637692373!2d-60.57478662492396!3d-33.89888212061661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b9b54fdc244819%3A0x8482bdde8df6473a!2s11%20de%20Septiembre%20351%2C%20B2700%20Pergamino%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1727474646978!5m2!1ses!2sar"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Map showing location at 11 de Septiembre, Pergamino, Buenos Aires"
          ></iframe>
        </div>

        <div className="flex justify-content-start w-full h-25rem map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.5049065644403!2d55.37514627443886!3d25.118615434880834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6f9f1fbfb607%3A0x4db0a2f5f59532d9!2sIFZA%20Business%20Park!5e0!3m2!1ses!2sar!4v1727476177939!5m2!1ses!2sar"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Map showing location at IFZA Business Park, Box342001, Dubai, EAU"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
