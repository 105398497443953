import { useState, useEffect } from 'react';
import { useResizeListener } from 'primereact/hooks';

export const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event) => {
      setWindowSize({
        width: event.currentTarget.innerWidth,
        height: event.currentTarget.innerHeight,
      });
    },
  });

  useEffect(() => {
    bindWindowResizeListener();
    return () => {
      unbindWindowResizeListener();
    };
  }, [bindWindowResizeListener, unbindWindowResizeListener]);

  return windowSize;
};
