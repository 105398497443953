import React, { useState } from 'react';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FloatLabel } from "primereact/floatlabel";
import { Button } from "primereact/button";

const ContactFormCorto = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const createMailtoLink = () => {
    const { name, email, message } = formData;

    // Verificar que los campos no estén vacíos
    if (!name || !email || !message) {
      alert("Por favor complete todos los campos antes de enviar.");
      return;
    }

    const mailto = `mailto:deecvrdesign@gmail.com?subject=${encodeURIComponent(`New message from ${name}`)}&body=${encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\n\nMessage:\n${message}`
    )}`;
    window.location.href = mailto;
  };

  return (
    <div className="grid grid-nogutter justify-content-center mb-4">
      <div className="flex flex-column w-10 md:w-8 lg:w-6 xl:w-6 justify-content-start align-items-center gap-6">
        <div className="col-10 md:col-8">
          <h2 className="text-center raleway-bold">
            Creative project? Let's have a productive talk.
          </h2>
        </div>
        {/* Formulario */}
        <div className="flex flex-column gap-6 jost-400 text-sm w-full">
          <div className="flex flex-column md:flex-row gap-6 justify-content-between">
            <div className="flex-grow-1 m-0 p-0">
              <FloatLabel>
                <InputText
                  id="name"
                  name="name"
                  autoComplete="off"
                  className="raleway-regular input-custom p-0"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <label htmlFor="name">Name</label>
              </FloatLabel>
            </div>
            <div className="flex-grow-1 m-0 p-0">
              <FloatLabel>
                <InputText
                  id="email"
                  name="email"
                  autoComplete="off"
                  className="raleway-regular input-custom p-0"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <label htmlFor="email">Email</label>
              </FloatLabel>
            </div>
          </div>
          <div className="m-0 p-0">
            <FloatLabel>
              <InputTextarea
                id="message"
                name="message"
                autoComplete="off"
                autoResize
                rows={1}
                className="raleway-regular input-custom p-1"
                value={formData.message}
                onChange={handleInputChange}
              />
              <label htmlFor="message">Message</label>
            </FloatLabel>
          </div>
        </div>
        <div>
          <Button
            label="Send now"
            icon="pi pi-arrow-right"
            iconPos="right"
            size="small"
            className="raleway-medium custom-btn-brown"
            onClick={createMailtoLink}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactFormCorto;
