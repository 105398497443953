import bgWhite from '../../assets/imgs/varios/banner_deec_ligth.webp';
import bgTeam from '../../assets/imgs/equipo/equipo.webp';

import manage1 from '../../assets/imgs/equipo/DAMIAN_SANCHEZ.webp';
import manage2 from '../../assets/imgs/equipo/FRANCISCO_VIGNOLA.webp';

import programmer from '../../assets/imgs/equipo/BOJANICH_PABLO.webp';
import arq1 from '../../assets/imgs/equipo/CARRICART_LAUTARO.webp';
import arq2 from '../../assets/imgs/equipo/MARCONATO_FACUNDO.webp';

import desginer from '../../assets/imgs/equipo/PATERLINI_SOFÍA.webp';


const equipo = {
    bg_banner: bgWhite,
    bg_equipo: bgTeam,
    managers: [
        {
            name: 'Francisco',
            last_name: 'Vignola',
            position: 'Commercial Manager',
            img: manage2,
        },
        {
            name: 'Damian',
            last_name: 'Sanchez',
            position: 'Commercial Manager',
            img: manage1,
        }
    ],
    team: [
        {
            name: 'Facundo',
            last_name: 'Marconato',
            position: 'Rendering',
            img: arq2,
        },
        {
            name: 'Sofia',
            last_name: 'Paterlini',
            position: 'Design',
            img: desginer,
        },
        {
            name: 'Pablo',
            last_name: 'Bojanich',
            position: 'Programming',
            img: programmer,
        },
        {
            name: 'Lautaro',
            last_name: 'Carricart',
            position: 'Modeling',
            img: arq1,
        }
    ]
};

export default equipo;
