import imagen1 from "../../assets/imgs/Proyects/Varios/living.webp";
import imagen2 from "../../assets/imgs/Proyects/Varios/varios_1.webp";

const info = [
  {
    imagen: imagen1,
    titulo: "What We Do",
    texto:
      "Learn about our creative process and the unique vision that shapes every project, from initial ideas to the final concept. Discover what sets us apart.",
    botonTexto: "Our Concept",
    link: "/services",
  },
  {
    imagen: imagen2,
    titulo: "The End Result",
    texto:
      "See our completed projects that reflect our dedication to quality and detail. Explore the results of our work and the impact it has on modern design.",
    botonTexto: "Our Portfolio",
    link: "/proyect",
  },
];

export default info;
