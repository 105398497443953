import React from "react";
import "./Services.css";

import NavBar from "../../components/NavBar/NavBar";
import SEO from "../../utils/SEO";
import BannerSeccion from "../../components/BannerSeccion/BannerSeccion";
import ServicesImage from "../../assets/imgs/Proyects/StandDubai/Estaticas/StandDEECDubai_4.webp";

import SteppersServices from "../../components/SteppersServices/SteppersServices";
import BannerPie from "../../components/BannerPie/BannerPie";
import Footer from "../../components/Footer/Footer";
import SummaryServices from "../../components/SummaryServices/SummaryServices";

export default function Services() {
  return (
    <div className="Services flex flex-column justify-content-center align-content-center gap-6">
      <SEO
        title="DEEC | Services"
        description="Descubre los servicios de diseño arquitectónico y renderizado 3D que ofrecemos para proyectos inmobiliarios. Desde conceptos hasta visualizaciones finales."
        keywords="servicios, diseño arquitectónico, renderizado 3D, proyectos inmobiliarios, visualizaciones"
        author="DEEC"
        ogTitle="DEEC | Nuestros Servicios"
        ogDescription="Explora los servicios de diseño arquitectónico y renderizado 3D que ofrecemos para dar vida a tus proyectos inmobiliarios."
        ogImage="URL de una imagen representativa de los servicios"
        ogUrl="URL de la página Services"
      />

      <NavBar />
      <BannerSeccion imageUrl={ServicesImage} title="Services" />
      <SummaryServices />
      <SteppersServices />
      <BannerPie />
      <Footer />
    </div>
  );
}
