import React from "react";
import "./SummaryServices.css";

import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";

import { useWindowResize } from "../../utils/hooks/useWindowResize";
import { useNavigate } from "react-router-dom";
import servicios from "../../utils/data/services";

export default function SummaryServices() {
  useWindowResize();
  const navigate = useNavigate();

  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const servicesTemplate = (service) => {
    return (
      <div className="summary-services-card flex justify-content-center align-content-center flipright animation-duration-400 animation-iteration-1">
        <div className="w-8">
          <h2 className="raleway-bold text-xl">{service.title}</h2>
          <p className="raleway-regular text-sm">{service.description}</p>
          <Button
            text
            label={service.action}
            severity="secondary"
            icon="pi pi-arrow-right"
            iconPos="right"
            className="raleway-medium text-xs custom-btn-transparent"
            onClick={() => navigate("/single-service", { state: { service } })}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="SummaryServices grid grid-nogutter justify-content-center align-content-center">
      <Carousel
        className="col-10 md:col-8"
        value={servicios}
        numVisible={3}
        numScroll={3}
        responsiveOptions={responsiveOptions}
        itemTemplate={servicesTemplate}
        circular={false}
        showNavigators={false}
        autoplayInterval={5000}
      />
    </div>
  );
}
