import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import "primeflex/primeflex.css";
import "./SingleServicePortfolio.css";

import proyects from "../../utils/data/proyects";
import fallbackImage from "../../assets/imgs/varios/background1.webp";

const SingleServicePortfolio = () => {
  const [content, setContent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * proyects.length);
    setContent(proyects[randomIndex]);
  }, []);

  if (!content) return null;

  const handleProjectClick = () => {
    navigate("/proyect");
  };

  return (
    <div
      className="grid grid-nogutter col-12 justify-content-center align-items-center m-0 p-0"
      data-aos="fade-right"
    >
      <div className="flex flex-column w-10 md:flex-row h-auto justify-content-center align-items-center gap-3 m-0">
        <div className="image-container8 border-solid border-1">
          <img
            className="image-custom5"
            src={content?.files.staticImages[0] || fallbackImage}
            alt={content?.titulo_proyecto || "Fallback Image"}
            onError={(e) => {
              e.target.src = fallbackImage; // Cambia a fallback si la carga falla
            }}
          />
        </div>
        <div className="w-full md:w-3 md:text-left">
          <h2 className="raleway-bold text-2xl m-0">
            We love design. That's how we got here.
          </h2>
          <p className="raleway-regular text-sm">
            Explore the stunning projects we've completed, showcasing our
            passion for perfection.
          </p>
          <Button
            label="Our Portfolio"
            className="raleway-medium custom-btn-light"
            icon="pi pi-arrow-right"
            iconPos="right"
            size="small"
            onClick={handleProjectClick}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleServicePortfolio;
