import React from "react";
import "./ProyectDetailsIntro.css";
import { useLocation } from "react-router-dom";

export default function ProyectDetailsIntro() {
  const location = useLocation();
  const { proyecto } = location.state || {};

  if (!proyecto) {
    return <p>Proyect data is not available.</p>;
  }

  return (
    <div className="ProyectDetailsIntro grid grid-nogutter col-12 text-sm justify-content-center m-0 p-0">
      <div
        className="flex flex-column w-full md:flex-row md:w-10 h-auto m-0 p-0 align-items-center justify-content-center"
        data-aos="fade-up"
      >
        <div className="flex flex-row w-full md:w-3 text-left p-0 mr-3">
          <div className="flex flex-column m-3 raleway-semibold text-xs">
            <p>Client</p>
            <p>Category</p>
            <p>Tags</p>
            <p>Date</p>
          </div>
          <div className="flex flex-column m-3 raleway-regular text-xs">
            <p>{proyecto.client}</p>
            <p>{proyecto.category}</p>
            <p>{proyecto.tag}</p>
            <p>{proyecto.date}</p>
          </div>
        </div>
        <div className="flex flex-column w-full md:w-5 text-left m-3">
          <h1 className="raleway-bold">{proyecto.titulo_proyecto}</h1>
          <div className="raleway-regular">
            {proyecto.description.split("\n\n").map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
