import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import BannerSeccion from "../../components/BannerSeccion/BannerSeccion";
import Footer from "../../components/Footer/Footer";
import SingleServiceIntro from "../../components/SingleServiceIntro/SingleServiceIntro";
import SingleServiceImage from "../../assets/imgs/Proyects/CasaDePlaya2/Estaticas/CasaDePlaya_1.webp";
import SingleServiceMedia from "../../components/SingleServiceMedia/SingleServiceMedia";
import BannerSponsors from "../../components/BannerSponsors/BannerSponsors";
import SingleServicePortfolio from "../../components/SingleServicePortfolio/SingleServicePortfolio";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../utils/SEO";

export default function SingleServices() {
  const location = useLocation();
  const { service } = location.state || {};

  useEffect(() => {
    // Desplazar la página hacia arriba al recibir un nuevo servicio
    window.scrollTo(0, 0);
  }, [service]);

  return (
    <div className="SingleServices flex flex-column justify-content-start align-content-center gap-6">
      <SEO
        title="DEEC | Detalles de Servicios"
        description="Explora nuestros servicios arquitectónicos únicos. Descubre en detalle cómo nuestros servicios de renderizado 3D pueden transformar tus proyectos inmobiliarios."
        keywords="servicios, arquitectura, renderizado 3D, diseño arquitectónico, servicios inmobiliarios, portfolios, multimedia"
        author="DEEC"
        ogTitle="DEEC | Detalles de nuestros servicios arquitectónicos"
        ogDescription="Descubre en detalle cada uno de nuestros servicios arquitectónicos, incluyendo renderizado 3D y soluciones personalizadas para tus proyectos."
        ogImage={SingleServiceImage} // Imagen representativa del servicio
        ogUrl="URL de la página de detalles del servicio"
      />

      <NavBar />
      <BannerSeccion imageUrl={SingleServiceImage} title="Service Details" />
      <SingleServiceIntro />
      <SingleServiceMedia />
      <BannerSponsors />
      <SingleServicePortfolio />
      <Footer />
    </div>
  );
}
