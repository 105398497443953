import image1 from "../../assets/imgs/Proyects/CasaDePlaya2/Estaticas/CasaDePlaya_2.webp";
import image2 from "../../assets/imgs/Proyects/Glass/Estaticas/CasaDeCristal_2.webp";
import image3 from "../../assets/imgs/Proyects/Kaufman/Estaticas/kaufman_1.webp";
import image4 from "../../assets/imgs/Proyects/CasaDePlaya1/Estaticas/casaPlaya1_1.png";
import image5 from "../../assets/imgs/Proyects/Casa_Fransworth/Estaticas/CasaFransworth_2.webp";
import image6 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_principalBuilding_5.webp";
import image7 from "../../assets/imgs//Proyects/StandDubai/Estaticas/StandDEECDubai_3.webp";

const carrouselImages = [
  { src: image1, alt: "imagen1" },
  { src: image2, alt: "imagen2" },
  { src: image3, alt: "imagen3" },
  { src: image4, alt: "imagen4" },
  { src: image5, alt: "imagen5" },
  { src: image6, alt: "imagen6" },
  { src: image7, alt: "imagen7" },
];

export default carrouselImages;
