import video1 from "../../assets/videos/3dcontentservice.mp4";
import video2 from "../../assets/videos/luxrealityservice1.mp4";
import video3 from "../../assets/videos/luxrealityservice2.mp4";
import video4 from "../../assets/videos/smart360service.mp4";

const servicios = [
  {
    title: "3D CONTENT",
    description: "We generate three-dimensional images and videos.",
    intro:
      "We generate three-dimensional images and videos to provide you with a complete view of both the exterior and interior of your project. With our 3D renderings, you can see every detail and visualize your architectural vision.",
    action: "Read More",
    points: [
      "We provide high quality design services.",
      "Project on time and Latest Design.",
      "Scientific Skills For getting a better result.",
      "Renovations Benefit of Service.",
      "We are confident about our projects.",
    ],
    video: [video1],
  },
  {
    title: "SMART 360°",
    description:
      "We offer a service that provides immersive 360-degree experiences.",
    intro:
      "We offer a service that provides immersive 360-degree experiences. We use specialized photographs and videos to allow our clients to virtually explore an environment from any angle.",
    action: "Read More",
    points: [
      "We provide high quality design services.",
      "Project on time and Latest Design.",
      "Scientific Skills For getting a better result.",
      "Renovations Benefit of Service.",
      "We are confident about our projects.",
    ],
    video: [video4],
  },
  {
    title: "LUX REALITY",
    description:
      "Our technology allow you to walk and explore these environments.",
    intro:
      "With our technology, we not only transport you to virtual worlds, but also allow you to walk through and explore these environments, providing you with an immersive and interactive experience that pushes the boundaries of reality.",
    action: "Read More",
    points: [
      "We provide high quality design services.",
      "Project on time and Latest Design.",
      "Scientific Skills For getting a better result.",
      "Renovations Benefit of Service.",
      "We are confident about our projects.",
    ],

    video: [video2, video3],
  },
];

export default servicios;
