import React from "react";
import "./SteppersCard.css";

export default function SteppersCard({ service }) {
  return (
    <div
      className={`SteppersCard flex justify-content-center align-items-center w-full h-auto mb-8 ${
        service.indice % 2 !== 0
          ? "flex-column md:flex-row"
          : "flex-column md:flex-row-reverse"
      }`}
      data-aos={service.indice % 2 !== 0 ? "fade-left" : "fade-right"}
    >
      <div className="w-full h-10rem md:w-6 md:h-20rem flex justify-content-center align-items-center">
        <div
          className={`w-full md:w-21rem h-4rem md:h-full imageContainer ${
            service.indice % 2 !== 0
              ? "imageContainerImpar"
              : "imageContainerPar"
          }`}
          style={{ backgroundImage: `url(${service.img})` }}
        ></div>
      </div>
      <div className="w-full md:w-6 h-full">
        <div className="flex flex-column justify-content-start align-content-start h-full md:px-4 md:pb-4 lg:px-8 lg:pb-8">
          <div
            className={`flex ${
              service.indice % 2 !== 0
                ? "justify-content-start md:justify-content-end"
                : "justify-content-end md:justify-content-start"
            }`}
          >
            <p className="raleway-bold text-7xl m-0 indice">{service.indice}</p>
          </div>
          <div className="flex flex-column">
            <h3 className="raleway-bold text-2xl text-left m-0 mb-2">
              {service.title}
            </h3>
            <p className="raleway-regular text-sm text-left m-0">
              {service.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
