import React from "react";
import "./NavBar.css";

import { useNavigate } from "react-router-dom";

/* PRIME REACT */
import { Menubar } from "primereact/menubar";

import DEEC from "../../assets/imgs/logos/BLANCO.webp";

export default function NavBar() {
  /* EJEMPLO DE USO - COMPONENTES DE PRIME REACT */
  const navigate = useNavigate();

  const handleNavegate = (ruta) => {
    if (ruta) {
      navigate(`/${ruta}`);
    } else {
      navigate(`/`);
    }
  };

  const items = [
    {
      label: "Home",
      className: "raleway-medium",
      icon: "",
      command: () => {
        handleNavegate("");
      },
    },
    {
      label: "About us",
      className: "raleway-medium",
      icon: "",
      command: () => {
        handleNavegate("about-us");
      },
    },
    {
      label: "Services",
      className: "raleway-medium",
      icon: "",
      command: () => {
        handleNavegate("services");
      },
    },
    {
      label: "Proyects",
      className: "raleway-medium",
      icon: "",
      command: () => {
        handleNavegate("proyect");
      },
    },
    {
      label: "Contact us",
      className: "raleway-medium",
      icon: "",
      command: () => {
        handleNavegate("contact-us");
      },
    },
    {
      label: "FAQ's",
      className: "raleway-medium",
      icon: "",
      command: () => {
        handleNavegate("faqs");
      },
    },
  ];

  <svg></svg>;

  const start = <img src={DEEC} alt="logo-deec" width={100} onClick={() => handleNavegate("")} className="cursor-pointer" />;

  return (
    <div className="card mb-6">
      <Menubar
        model={items}
        start={start}
        className="justify-content-evenly text-xs border-0 bg-transparent"
      />
    </div>
  );
}
