import React from "react";
import "./SingleServiceIntro.css";
import { useLocation } from "react-router-dom";

export default function SingleServiceIntro() {
  const location = useLocation();
  const { service } = location.state || {};

  if (!service) {
    return <p>Service data is not available.</p>;
  }

  return (
    <div className="SingleServiceIntro grid grid-nogutter col-12 text-sm justify-content-center m-0 p-0">
      <div
        className="flex flex-column w-full md:w-10 h-auto align-items-center justify-content-center m-0 p-0"
        data-aos="fade-up"
      >
        <div className="flex flex-column w-6 text-center">
          <h1 className="raleway-bold">{service.title}</h1>
          <p className="raleway-medium">{service.description}</p>
        </div>
        <div className="flex flex-column w-10 md:w-8 aligns-content-center justify-content-center text-left gap-4 p-3">
          <p className="raleway-regular text-xs responsive-columns">
            {service.intro}
          </p>
        </div>
      </div>
    </div>
  );
}
