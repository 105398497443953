import React from "react";
import "./BannerSponsors.css";
import "primeflex/primeflex.css";
import sponsors from "../../utils/data/sponsors";

export default function BannerSponsors() {
  return (
    <div className="grid grid-nogutter nested-grid col-12 justify-content-center m-0 p-0">
      <div className="grid grid-nogutter nested-grid col-10 gap-6">
        {sponsors.map((sponsor, index) => (
          <div
            key={index}
            className="col h-7rem container-img-sponsor"
            style={{ backgroundImage: `url(${sponsor.src})` }}
          >
          </div>
        ))
        }
      </div>
    </div >
  );
}
