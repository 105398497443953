import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
import Modal from "react-modal";
Modal.setAppElement("#root");
// register Swiper custom elements
register();

const root = ReactDOM.createRoot(document.getElementById("root"));

const loadAOS = async () => {
  const AOS = await import("aos"); // Cargar AOS de forma asíncrona
  await import("aos/dist/aos.css"); // Cargar los estilos de AOS de forma asíncrona
  AOS.init({ once: true, easing: "ease-in-sine" });
};

// Ejecutar la función para cargar AOS una vez que el DOM esté listo
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// Llamamos a la función loadAOS después de que la aplicación ya está montada
document.addEventListener("DOMContentLoaded", loadAOS);

/* const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <BrowserRouter>
    <App />
  </BrowserRouter>

); */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
