import React from "react";
import "./CardTemplate.css";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

export default function CardTemplate({ proyecto }) {
  const navigate = useNavigate();

  const handleReadMore = () => {
    navigate("/proyect-details", { state: { proyecto } });
  };
  return (
    <div className="grid grid-nogutter align-content-center justify-content-center p-0 m-0">
      <div className="CardTemplateSummaryProyects grid grid-nogutter nested-grid align-content-center gap-3 w-11 border-1 border-white p-4 cursor-pointer">
        <div
          className="CardTemplateImage col-12 h-12rem bg-green-100 relative"
          style={{ backgroundImage: `url(${proyecto.files.staticImages[0]})` }}
        >
          <span className="text-xs font-light text-white border-round absolute bottom-0 left-0 mb-2 ml-2 p-2">
            {proyecto.sub_titulo_proyecto}
          </span>
        </div>
        <div className="col-12 h-3rem flex justify-content-start align-items-center">
          <p className="m-0 text-left ">{proyecto.titulo_proyecto}</p>
        </div>
        <div className="col-12 h-3rem flex justify-content-between align-items-center">
          <p className="text-xs font-light m-0">{proyecto.date}</p>
          <Button
            rounded
            size="small"
            icon="pi text-2xl pi-angle-right"
            aria-label="Filter"
            className="custom-btn-brown hover:shadow-4"
            onClick={handleReadMore}
          />
        </div>
      </div>
    </div>
  );
}
