import img1 from "../../assets/imgs/Work/infogateringdge_2.webp";
import img2 from "../../assets/imgs/Work/modeling_1.webp";
import img3 from "../../assets/imgs/Work/texturing.webp";
import img4 from "../../assets/imgs/Work/rendering_2.webp";
import img5 from "../../assets/imgs/Work/vr_configuration.webp";

const steppersServices = [
  {
    indice: "01",
    title: "Information gateringdge",
    description:
      "The first stage involves gathering all the necessary information about the project. This includes design specifications, visual references, and any other relevant details. We also strive to understand the client's specific requirements in terms of style, lighting, materials, and more. This stage includes presenting ideas for both the exterior and interior design of the project.",
    img: img1,
  },
  {
    indice: "02",
    title: "Modeling",
    description:
      "The second stage involves creating a three-dimensional model of the environment to be rendered. This includes the building structure, furniture, accessories, and other elements present in the design.",
    img: img2,
  },
  {
    indice: "03",
    title: "Texturing",
    description:
      "Once the basic modeling is complete, textures are applied to objects to simulate their visual characteristics. This includes textures for walls, floors, fabrics, metals, and more. Textures can be created from scratch, photographed, or downloaded.",
    img: img3,
  },
  {
    indice: "04",
    title: "Rendering",
    description:
      "This process involves calculating the final appearance of the scene based on the lighting, materials, textures, and other configured effects. Once complete, the rendered images are delivered to the client in the agreed-upon format and resolution. The client can request revisions or additional adjustments as needed.",
    img: img4,
  },
  {
    indice: "05",
    title: "VR Configuration",
    description:
      "In our VR service, we handle all the necessary setup to provide immersive 3D experiences. We use specialized software, including graphics engines and VR platforms, and create and optimize three-dimensional content through modeling, texturing, and animation. We adjust the environment to ensure interactivity and comfort, combining all these elements to deliver realistic and captivating virtual reality experiences.",
    img: img5,
  },
];

export default steppersServices;
