/* import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop; */

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Cada vez que la ruta cambie, hace scroll hacia arriba
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handleSameLinkClick = (event) => {
      // Si el clic es en un enlace y apunta a la misma ruta
      if (
        event.target.tagName === "A" &&
        event.target.href.endsWith(pathname)
      ) {
        window.scrollTo(0, 0);
      }
    };

    // Agrega el event listener para detectar clics en enlaces
    window.addEventListener("click", handleSameLinkClick);

    // Limpia el event listener cuando se desmonte el componente
    return () => {
      window.removeEventListener("click", handleSameLinkClick);
    };
  }, [pathname]);

  return null;
}

export default ScrollToTop;
