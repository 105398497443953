import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactPannellum from "react-pannellum";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import { Carousel } from "primereact/carousel";
import "./ProyectDetails360.css";

export default function ProyectDetails360() {
  const location = useLocation();
  const { proyecto } = location.state || {};

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (proyecto && proyecto.files.images360.length > 0) {
      setSelectedImage(proyecto.files.images360[0]);
    }
  }, [proyecto]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const thumbnailTemplate = (image, index) => {
    return (
      <img
        key={index}
        src={image}
        alt={`Panorámica ${index + 1}`}
        onClick={() => handleImageClick(image)}
        className={`thumbnail-image ${
          selectedImage === image ? "selected" : ""
        }`}
      />
    );
  };

  if (
    !proyecto ||
    !proyecto.files.images360 ||
    proyecto.files.images360.length === 0
  ) {
    return null; // No renderizar nada si no hay imágenes 360
  }

  return (
    <div className="ProyectDetails360 grid grid-nogutter col-12 justify-content-center m-0 p-0">
      <div className="flex flex-column w-full md:w-10 h-auto align-items-center justify-content-center m-0 p-0 mb-4 p-2">
        <div className="viewer-container w-full md:w-8 p-0" data-aos="fade-up">
          {selectedImage && (
            <ReactPannellum
              key={selectedImage} // Cambio de key para forzar el remount
              id="panorama"
              sceneId="dynamicScene"
              imageSource={selectedImage}
              config={{
                autoLoad: true,
                showFullscreenCtrl: true,
                showZoomCtrl: true,
              }}
              style={{ width: "100%", height: "350px" }}
            />
          )}
        </div>
        <div className="w-full md:w-8 mt-4 p-0" data-aos="fade-up">
          <Carousel
            value={proyecto.files.images360}
            numVisible={3}
            numScroll={2}
            responsiveOptions={responsiveOptions}
            itemTemplate={thumbnailTemplate}
          />
        </div>
      </div>
    </div>
  );
}
