import React from "react";
import "./SteppersServices.css";

import steppersServices from "../../utils/data/stepperServices";
import SteppersCard from "./SteppersCard/SteppersCard";

export default function SteppersServices() {
  return (
    <div className="SteppersServices grid grid-nogutter">
      <div className="col-12 grid grid-nogutter justify-content-center align-content-center">
        <div className="col-10 md:col-5 h-15rem mb-6" data-aos="fade-up">
          <h2 className="text-center raleway-bold text-3xl">How We Work</h2>
          <p className="raleway-regular">
            Our workflow ensures precision at every stage, from the initial
            gathering of information to the final delivery. Each step is
            designed to bring your vision to life, whether through detailed
            modeling, realistic textures, high-quality rendering, or immersive
            VR experiences.
          </p>
        </div>

        <div className="col-10">
          {steppersServices.map((service, index) => (
            <div className="col-12" key={index}>
              <SteppersCard service={service} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
