import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import BannerSeccion from "../../components/BannerSeccion/BannerSeccion";
import Footer from "../../components/Footer/Footer";
import ProyectDetailsImage from "../../assets/imgs/Proyects/CasaDePlaya2/Estaticas/CasaDePlaya_6.webp";
import ProyectDetailsIntro from "../../components/ProyectDetailsIntro/ProyectDetailsIntro";
import ProyectDetailsVideo from "../../components/ProyectDetailsVideo/ProyectDetailsVideo";
import ProyectDetails360 from "../../components/ProyectDetails360/ProyectDetails360";
import ProyectDetailsPhotos from "../../components/ProyectDetailsPhotos/ProyectDetailsPhotos";

import SEO from "../../utils/SEO";

export default function ProyectDetails() {
  return (
    <div className="ProyectDetails flex flex-column justify-content-start align-content-center gap-6">
      <SEO
        title="DEEC | Detalles del Proyecto"
        description="Descubre en detalle nuestros innovadores proyectos arquitectónicos. Explora imágenes, videos, vistas 360 y más, para sumergirte en la visión completa de cada diseño."
        keywords="detalles del proyecto, arquitectura, renderizado 3D, proyectos inmobiliarios, imágenes, videos, vistas 360, diseño arquitectónico"
        author="DEEC"
        ogTitle="DEEC | Detalles completos de nuestros proyectos arquitectónicos"
        ogDescription="Explora a fondo cada proyecto arquitectónico con detalles que incluyen imágenes estáticas, videos, vistas 360 y galerías de fotos."
        ogImage={ProyectDetailsImage}  // Imagen del proyecto destacado
        ogUrl="URL de la página de detalles del proyecto"
      />

      <NavBar />
      <BannerSeccion imageUrl={ProyectDetailsImage} title="Proyect Details" />
      <ProyectDetailsIntro />
      <ProyectDetailsVideo />
      <ProyectDetails360 />
      <ProyectDetailsPhotos />
      <Footer />
    </div>
  );
}
