import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import icon from "../../../src/assets/imgs/logos/BLANCO.webp";
import servicios from "../../utils/data/services";
import Social from "../../components/Social/Social";
import "flag-icons/css/flag-icons.min.css";

export default function Footer() {
  return (
    <div className="grid grid-nogutter col-12 text-sm justify-content-center m-0 p-0">
      <div className="flex flex-column md:flex-row w-10 h-auto md:h-15rem align-items-start text-left m-0 p-0">
        <div className="flex flex-column w-full md:w-3 text-left align-items-start m-2">
          <div>
            <img src={icon} alt="icono" className="w-5rem md:w-8rem ml-2" />
          </div>
          <div>
            <p className="m-2 raleway-medium text-sm">
              Take your proyects to the next level.
            </p>
          </div>
          <Social />
        </div>

        <div className="flex flex-column w-full md:w-3 align-items-start m-2">
          <p className="raleway-semibold m-1 mb-2 text-base">Pages</p>
          <div className="raleway-regular m-1 text-sm">
            <p>
              <Link to="/about-us">About Us</Link>
            </p>
            <p>
              <Link to="/services">Services</Link>
            </p>
            <p>
              <Link to="/proyect">Proyects</Link>
            </p>
            <p>
              <Link to="/contact-us">Contact Us</Link>
            </p>
            <p>
              <Link to="/faqs">FAQ's</Link>
            </p>
          </div>
        </div>

        <div className="flex flex-column w-full md:w-3 align-items-start m-2">
          <p className="raleway-semibold m-1 mb-2 text-base">Services</p>
          <div className="raleway-regular m-1 text-sm">
            <p>
              <Link to="/single-service" state={{ service: servicios[0] }}>
                3D Content
              </Link>
            </p>
            <p>
              <Link to="/single-service" state={{ service: servicios[1] }}>
                Smart 360
              </Link>
            </p>
            <p>
              <Link to="/single-service" state={{ service: servicios[2] }}>
                Lux reality
              </Link>
            </p>
          </div>
        </div>

        <div className="flex flex-column w-full md:w-3 justify-content-start items-center m-2">
          <p className="raleway-semibold m-1 mb-2 text-base">Contact</p>
          <div className="raleway-regular m-1 text-sm">
            <span className="fi fi-ar"></span>
            <p>11 de septiembre 351, Pergamino</p>
            <p>Buenos Aires, Argentina</p>
            <p>+54 2477 201703</p>
          </div>
          <div className="raleway-regular m-1 text-sm">
            <span className="fi fi-ae"></span>
            <p>IFZA Business Park, Box342001</p>
            <p>Dubai, EAU</p>
            <p>+971 58 191 1703</p>
          </div>
        </div>
      </div>

      <div className="flex justify-content-center w-full p-0 mt-8">
        <p className="text-center raleway-semibold text-sm copy">
          Copyright © DEEC
        </p>
      </div>
    </div>
  );
}
