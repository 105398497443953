import auspiciante1 from "../../assets/imgs/Sponsors/BLANCO/Recurso16.png";
import auspiciante2 from "../../assets/imgs/Sponsors/BLANCO/Recurso17.png";
import auspiciante3 from "../../assets/imgs/Sponsors/BLANCO/Recurso18.png";
import auspiciante4 from "../../assets/imgs/Sponsors/BLANCO/Recurso19.png";
import auspiciante5 from "../../assets/imgs/Sponsors/BLANCO/Recurso20.png";

const sponsors = [
  { src: auspiciante1, alt: "Auspiciante 1" },
  { src: auspiciante2, alt: "Auspiciante 2" },
  { src: auspiciante3, alt: "Auspiciante 3" },
  { src: auspiciante4, alt: "Auspiciante 4" },
  { src: auspiciante5, alt: "Auspiciante 5" },
];

export default sponsors;
