import img1 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_principalBuilding_6.webp";
import img2 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_13.webp";

export const faqs = [
  {
    image: img1,
    title: "Every question answered",
    faqs: [
      {
        question: "What is the Hipouch Interior Design Service?",
        answer:
          "Lorem ipsum dolor sit amet, adipiscing Aliquam eu sem vitae turpmaximus.posuere in.Contrary popular belief. There are many variations of passages of Lorem Ipsum available, but the majority randomised.",
      },
      {
        question: "So... how exactly does this work?",
        answer:
          "Lorem ipsum dolor sit amet, adipiscing Aliquam eu sem vitae turpmaximus.posuere in.Contrary popular belief. There are many variations of passages of Lorem Ipsum available, but the majority randomised.",
      },
      {
        question: "What cities do you currently operate in?",
        answer:
          "Lorem ipsum dolor sit amet, adipiscing Aliquam eu sem vitae turpmaximus.posuere in.Contrary popular belief. There are many variations of passages of Lorem Ipsum available, but the majority randomised.",
      },
      {
        question: "Hipcouch Interior Design Service?",
        answer:
          "Lorem ipsum dolor sit amet, adipiscing Aliquam eu sem vitae turpmaximus.posuere in.Contrary popular belief. There are many variations of passages of Lorem Ipsum available, but the majority randomised.",
      },
      {
        question: "What kind of interior designers do you have?",
        answer:
          "Lorem ipsum dolor sit amet, adipiscing Aliquam eu sem vitae turpmaximus.posuere in.Contrary popular belief. There are many variations of passages of Lorem Ipsum available, but the majority randomised.",
      },
    ],
  },
  {
    image: img2,
    title: "Project related questions",
    faqs: [
      {
        question: "How long does it take?",
        answer:
          "Lorem ipsum dolor sit amet, adipiscing Aliquam eu sem vitae turpmaximus.posuere in.Contrary popular belief. There are many variations of passages of Lorem Ipsum available, but the majority randomised.",
      },
      {
        question: "Can I use my existing furnishings?",
        answer:
          "Lorem ipsum dolor sit amet, adipiscing Aliquam eu sem vitae turpmaximus.posuere in.Contrary popular belief. There are many variations of passages of Lorem Ipsum available, but the majority randomised.",
      },
      {
        question: "I put into redesigning my interior?",
        answer:
          "Lorem ipsum dolor sit amet, adipiscing Aliquam eu sem vitae turpmaximus.posuere in.Contrary popular belief. There are many variations of passages of Lorem Ipsum available, but the majority randomised.",
      },
      {
        question: "What do your services cost?",
        answer:
          "Lorem ipsum dolor sit amet, adipiscing Aliquam eu sem vitae turpmaximus.posuere in.Contrary popular belief. There are many variations of passages of Lorem Ipsum available, but the majority randomised.",
      },
      {
        question: "Do you offer free consultations?",
        answer:
          "Lorem ipsum dolor sit amet, adipiscing Aliquam eu sem vitae turpmaximus.posuere in.Contrary popular belief. There are many variations of passages of Lorem Ipsum available, but the majority randomised.",
      },
    ],
  },
];
