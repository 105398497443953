import React from "react";
import "./AboutUs.css";
import SEO from "../../utils/SEO";
import NavBar from "../../components/NavBar/NavBar";
import ContactFormCorto from "../../components/ContactFormCorto/ContactFormCorto";
import Footer from "../../components/Footer/Footer";
import Carrousel from "../../components/Carrousel/Carrousel";
import SummaryAboutUs from "../../components/SummaryAboutUs/SummaryAboutUs";
import IntroductionAboutUs from "./IntroductionAboutUs/IntroductionAboutUs";
import dataEquipo from "../../utils/data/equipo";

const TeamMember = ({ member, isManager }) => (
  <div className={`col-6 md:col flex flex-column align-items-center justify-content-center z-5 ${isManager ? '' : 'mb-6 md:mb-2'}`}>
    <div
      className="bg_img_container w-10rem h-10rem border-3 shadow-3"
      style={{
        borderRadius: '100px',
        borderColor: '#5f4539',
        backgroundImage: `url(${member.img})`,
      }}
      data-aos="fade-up"
      data-aos-duration="400"
    />
    <p
      data-aos="fade-up"
      data-aos-duration="500"
      className="m-0 font-light text-xl text-center">{member.name}</p>
    <p
      data-aos="fade-up"
      data-aos-duration="600"
      className="m-0 font-light text-xl text-center">{member.last_name}</p>
    <p
      data-aos="fade-up"
      data-aos-duration="700"
      className="m-0 raleway-bold text-base text-center uppercase">{member.position}</p>
  </div>
);

const TeamSection = ({ title, background, team, isManager, borderRadius, filter = 'none', marginTop }) => (
  <div className="grid grid-nogutter nested-grid col-10 md:col-7 justify-content-center">
    <div
      className="bg_img_container col-12 shadow-2"
      style={{
        height: isManager ? '17rem' : '25rem',
        borderRadius,
        backgroundImage: `url(${background})`,
        filter,
      }}
      data-aos="fade-up"
      data-aos-duration="300"
    />
    <div
      className="col-12 grid grid-nogutter nested-grid justify-content-around" style={{ marginTop }}>
      {team.map((member, index) => (
        <TeamMember key={index} member={member} isManager={isManager} />
      ))}
    </div>
  </div>
);

export default function AboutUs() {
  return (
    <div className="AboutUs flex flex-column justify-content-start align-content-center gap-6">
      <SEO
        title="DEEC | About Us"
        description="Conoce más sobre DEEC, una empresa dedicada a ofrecer innovadores diseños arquitectónicos y servicios de renderizado 3D para proyectos inmobiliarios."
        keywords="sobre nosotros, DEEC, empresa de arquitectura, renderizado 3D, proyectos inmobiliarios"
        author="DEEC"
        ogTitle="DEEC | Sobre Nosotros"
        ogDescription="Conoce más sobre DEEC y nuestra dedicación a ofrecer innovadores diseños arquitectónicos y servicios de renderizado 3D."
        ogImage="URL de una imagen representativa del equipo o la empresa"
        ogUrl="URL de la página About Us"
      />

      <NavBar />
      <Carrousel />
      <IntroductionAboutUs />

      <div className="grid grid-nogutter col-12 justify-content-center m-0 p-0">
        <div className="flex flex-column w-10 md:w-8 h-auto align-items-center justify-content-center m-0 p-0">
          <p className="raleway-regular text-base text-center">
            We are an Argentinian company established in Dubai, specialized in
            rendering and virtual reality. We offer advanced 3D modeling
            services, immersive 360-degree experiences, and interactive virtual
            reality environments that take your projects to the next level.
          </p>
        </div>
      </div>

      <SummaryAboutUs />

      <div className="grid grid-nogutter nested-grid col-12 gap-6 justify-content-center m-0 my-6 p-0">
        <div className="col-12">
          <h1 className="raleway-bold text-6xl m-0">Our Team</h1>
        </div>

        {/* Managers Section */}
        <TeamSection
          title="Our Managers"
          background={dataEquipo.bg_banner}
          team={dataEquipo.managers}
          isManager={true}
          borderRadius="70px 0 70px 0"
          marginTop="-60px"
        />

        {/* Team Section */}
        <TeamSection
          title="Our Team"
          background={dataEquipo.bg_equipo}
          team={dataEquipo.team}
          isManager={false}
          borderRadius="0 70px 0 70px"
          filter="grayscale(100%)"
          marginTop="-60px"
        />
      </div>

      <ContactFormCorto />
      <Footer />
    </div>
  );
}
