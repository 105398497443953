import React from "react";
import "./Social.css";

export default function Social() {
  const openInstagram = () => {
    window.open("https://www.instagram.com/deec_design/", "_blank");
  };

  return (
    <div className="flex flex-row w-full align-items-start p-0 m-2 gap-2 social">
      <div>
        <i className="cursor-pointer pi pi-facebook pr-1" style={{ fontSize: "1rem" }}></i>
      </div>
      <div>
        <i className="cursor-pointer pi pi-twitter pr-1" style={{ fontSize: "1rem" }}></i>
      </div>
      <div>
        <i className="cursor-pointer pi pi-linkedin pr-1" style={{ fontSize: "1rem" }}></i>
      </div>
      <div>
        <i
          className="cursor-pointer pi pi-instagram pr-1"
          style={{ fontSize: "1rem" }}
          onClick={openInstagram}
        ></i>
      </div>
    </div>
  );
}
