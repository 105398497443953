/* import React, { useState, useEffect } from "react"; */
import React, { useState, useEffect, useRef } from "react";
import "./Proyects.css";
import NavBar from "../../components/NavBar/NavBar";
import SEO from "../../utils/SEO";
import BannerSeccion from "../../components/BannerSeccion/BannerSeccion";
import ProyectsImage from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_principalBuilding_2.webp";
import Footer from "../../components/Footer/Footer";

import CardProyect from "../../components/CardProyect/CardProyect";
import FilterToolbarProyects from "../../components/FilterToolbarProyects/FilterToolbarProyects";

import proyects from "../../utils/data/proyects.js";
import { Paginator } from "primereact/paginator";

export default function Proyects() {
  const [allProyects, setProyectos] = useState([]);
  const [filteredProyects, setFilteredProyects] = useState([]);
  const [paginatedProyects, setPaginatedProyects] = useState([]);
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const proyectsContainerRef = useRef(null);

  useEffect(() => {
    setProyectos(proyects);
    setFilteredProyects(proyects);
  }, []);

  useEffect(() => {
    const paginated = subdivideArray(filteredProyects, 4);
    setPaginatedProyects(paginated);
  }, [filteredProyects]);

  const handleFilterChange = (filterValue) => {
    if (filterValue === null) {
      setFilteredProyects(allProyects);
    } else {
      let filtered = [];
      switch (filterValue) {
        case 1:
          filtered = allProyects.filter((proyect) => proyect.tag === "House");
          break;
        case 2:
          filtered = allProyects.filter((proyect) => proyect.tag === "Spaces");
          break;
        case 3:
          filtered = allProyects.filter(
            (proyect) => proyect.tag === "Master Planning"
          );
          break;
        default:
          filtered = allProyects;
      }
      setFilteredProyects(filtered);
    }
  };

  // Paginador de arriba - no hace scroll
  const onPageChangeTop = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page);
  };

  // Paginador de abajo - hace scroll hacia los proyectos
  const onPageChangeBottom = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page);

    // Desplaza hacia el contenedor de proyectos solo si es el paginador de abajo
    if (proyectsContainerRef.current) {
      proyectsContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const subdivideArray = (items, size) => {
    const subArrays = [];
    for (let i = 0; i < items.length; i += size) {
      subArrays.push(items.slice(i, i + size));
    }
    return subArrays;
  };

  return (
    <div className="Proyects flex flex-column justify-content-start align-content-center gap-6">
      <SEO
        title="DEEC | Projects"
        description="Mira algunos de nuestros proyectos destacados en diseño arquitectónico y renderizado 3D. Inspírate con nuestros trabajos recientes."
        keywords="proyectos, portafolio, diseño arquitectónico, renderizado 3D, proyectos inmobiliarios"
        author="DEEC"
        ogTitle="DEEC | Nuestros Proyectos"
        ogDescription="Descubre algunos de nuestros proyectos destacados en diseño arquitectónico y renderizado 3D. Inspírate con nuestro portafolio."
        ogImage="URL de una imagen representativa de un proyecto"
        ogUrl="URL de la página Projects"
      />

      <NavBar />
      <BannerSeccion imageUrl={ProyectsImage} title="Our Proyects" />

      <div className="col-12 grid grid-nogutter nested-grid justify-content-center align-content-center">
        <div className="col-12 md:col-10 flex flex-column gap-4">
          <div className="h-4rem border-round">
            <FilterToolbarProyects onFilterChange={handleFilterChange} />
          </div>
          <Paginator
            first={first}
            rows={1}
            totalRecords={paginatedProyects.length}
            onPageChange={onPageChangeTop}
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            className="mb-4 p-0"
          />
        </div>
        <div className="col-10" ref={proyectsContainerRef}>
          <div className="grid grid-nogutter gap-3 justify-content-center">
            {paginatedProyects[currentPage]?.map((proyecto, index) => (
              <div
                className="col-12 sm:col-10 md:col-6 lg:col-5 xl:col-4"
                key={index}
              >
                <CardProyect proyecto={proyecto} />
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 md:col-10">
          <Paginator
            first={first}
            rows={1}
            totalRecords={paginatedProyects.length}
            onPageChange={onPageChangeBottom}
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            className="mb-4 p-0"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
