import React from "react";
import CardSummaryAboutUs from "./CardSummaryAboutUs/CardSummaryAboutUs";
import info from "../../utils/data/aboutUs";

export default function SummaryAboutUs() {
  return (
    <div className="p-2">
      {info.map((item, index) => (
        <CardSummaryAboutUs
          key={index}
          imagen={item.imagen}
          titulo={item.titulo}
          texto={item.texto}
          botonTexto={item.botonTexto}
          index={index}
          link={item.link}
        />
      ))}
    </div>
  );
}
