import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import "./ProyectDetailsPhotos.css";
import Modal from "react-modal";

export default function ProyectDetailsPhotos() {
  const location = useLocation();
  const { proyecto } = location.state || {};

  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (proyecto && proyecto.files.staticImages.length > 0) {
      setSelectedImage(proyecto.files.staticImages[0]);
    }
  }, [proyecto]);

  useEffect(() => {
    if (isModalOpen) {
      // Evita el desplazamiento en el body
      document.body.style.overflow = "hidden";
    } else {
      // Restaura el desplazamiento en el body
      document.body.style.overflow = "auto";
    }

    return () => {
      // Limpia el estilo al desmontar el componente
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  if (!proyecto) {
    return <p>Project data is not available.</p>;
  }

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setIsModalOpen(false); // Cierra el modal
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 2,
      numScroll: 1,
    },
  ];

  const thumbnailTemplate = (image, index) => {
    return (
      <img
        key={index}
        src={image}
        alt={`Imagen estática ${index + 1}`}
        onClick={() => handleImageClick(image)}
        className={`thumbnail-image-circle ${
          selectedImage === image ? "selected" : ""
        }`}
      />
    );
  };

  return (
    <div className="ProyectDetailsPhotos grid grid-nogutter col-12 justify-content-center m-0 p-0">
      <div className="flex flex-column w-full md:w-10 h-auto align-items-center justify-content-center m-0 p-0 mb-4 p-2">
        <div className="photo-container w-full md:w-8 p-0" data-aos="fade-up">
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              className="selected-image p-0"
              onClick={() => setIsModalOpen(true)}
            />
          )}
        </div>
        <div className="w-full md:w-8 mt-4" data-aos="fade-up">
          <Carousel
            value={proyecto.files.staticImages}
            numVisible={5}
            numScroll={4}
            responsiveOptions={responsiveOptions}
            itemTemplate={thumbnailTemplate}
          />
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="modal"
        style={{
          overlay: {
            zIndex: 1000,
          },
          content: {
            zIndex: 1001,
          },
        }}
      >
        <div className="modal-content">
          <img
            src={selectedImage}
            alt="Full Screen"
            className="full-screen-image"
            onClick={closeModal} // Cerrar el modal al hacer clic en la imagen
          />
          <Button
            className="close-modal"
            icon="pi pi-times"
            onClick={closeModal}
          ></Button>
        </div>
      </Modal>
    </div>
  );
}
