import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import "./ProyectDetailsVideo.css";

export default function ProyectDetailsVideo() {
  const location = useLocation();
  const { proyecto } = location.state || {};

  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    if (proyecto && proyecto.files.videos.length > 0) {
      setSelectedVideo(proyecto.files.videos[0]);
    }
  }, [proyecto]);

  if (!proyecto) {
    return <p>Project data is not available.</p>;
  }

  if (!proyecto || !proyecto.files || proyecto.files.videos.length === 0) {
    return null; // No se renderiza si no hay videos
  }

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 2,
      numScroll: 1,
    },
  ];

  const videoTemplate = (video, index) => {
    return (
      <video
        key={index}
        src={video}
        className={`thumbnail-video-circle ${
          selectedVideo === video ? "selected" : ""
        }`}
        onClick={() => handleVideoClick(video)}
      />
    );
  };

  return (
    <div className="ProyectDetailsVideo grid grid-nogutter col-12 justify-content-center m-0 p-0">
      <div className="flex flex-column w-full md:w-10 h-auto align-items-center justify-content-center m-0 p-0 mb-4">
        <div
          className="flex justify-content-center w-full md:w-8"
          data-aos="fade-up"
        >
          {selectedVideo && (
            <video
              key={selectedVideo} // Forzar el remontaje usando la URL del video
              controls
              className="proyect-video w-full"
            >
              <source src={selectedVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        <div className="w-full md:w-8 mt-4" data-aos="fade-up">
          <Carousel
            value={proyecto.files.videos}
            numVisible={5}
            numScroll={4}
            responsiveOptions={responsiveOptions}
            itemTemplate={videoTemplate}
          />
        </div>
      </div>
    </div>
  );
}
