import React from "react";
import "./ContactUs.css";
import SEO from "../../utils/SEO";
import NavBar from "../../components/NavBar/NavBar";
import BannerSeccion from "../../components/BannerSeccion/BannerSeccion";
import ContactImage from "../../assets/imgs/Proyects/Casa1/Estaticas/casa1_1.webp";
import Footer from "../../components/Footer/Footer";
import ContactFormLargo from "../../components/ContactFormLargo/ContactFormLargo";
import Map from "../../components/Map/Map";

export default function ContactUs() {
  return (
    <div className="ContactUs flex flex-column justify-content-start align-content-center gap-6">
      <SEO
        title="DEEC | Contact Us"
        description="Ponte en contacto con DEEC para más información sobre nuestros servicios de diseño arquitectónico y renderizado 3D. Estamos aquí para ayudarte con tus proyectos inmobiliarios."
        keywords="contacto, DEEC, información, servicios de arquitectura, renderizado 3D, proyectos inmobiliarios"
        author="DEEC"
        ogTitle="DEEC | Contáctanos"
        ogDescription="Contáctanos para más información sobre nuestros servicios de diseño arquitectónico y renderizado 3D. Estamos aquí para ayudarte con tus proyectos."
        ogImage="URL de una imagen representativa de contacto"
        ogUrl="URL de la página Contact Us"
      />

      <NavBar />
      <BannerSeccion imageUrl={ContactImage} title="Contact Us" />
      <ContactFormLargo />
      <Map />
      <Footer />
    </div>
  );
}
