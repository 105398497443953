import React from "react";
import "./Home.css";

/* HOOKS Y SEO */
import SEO from "../../utils/SEO";
import usePageTitle from "../../utils/hooks/usePageTitle";

/* COMPONENTES */
import NavBar from "../../components/NavBar/NavBar";
import Portada from "../../components/Portada/Portada";
import SummaryService from "../../components/SummaryServices/SummaryServices";
import BannerPie from "../../components/BannerPie/BannerPie";
import Portada2 from "../../components/Portada2/Portada2";
import BannerSponsors from "../../components/BannerSponsors/BannerSponsors";
import SummaryProyects from "../../components/SummaryProyects/SummaryProyects";
import Footer from "../../components/Footer/Footer";

export default function Home() {
  usePageTitle("DEEC | Home");

  return (
    <div className="Home flex flex-column justify-content-start align-content-center gap-6">
      {/* EJEMPLO APLICACIONES DE SEO */}
      <SEO
        title="DEEC | Home"
        description="Explora innovadores diseños arquitectónicos y servicios de renderizado 3D para proyectos inmobiliarios. Descubre cómo nuestros modelos 3D pueden dar vida a tus ideas."
        keywords="arquitectura, renderizado 3D, inmobiliarios, diseños arquitectónicos, modelos 3D, proyectos inmobiliarios"
        author="DEEC"
        ogTitle="DEEC | Diseños arquitectónicos y renderizado 3D para proyectos inmobiliarios"
        ogDescription="Explora innovadores diseños arquitectónicos y servicios de renderizado 3D para proyectos inmobiliarios. Descubre cómo nuestros modelos 3D pueden dar vida a tus ideas."
        ogImage="URL de una imagen representativa del trabajo"
        ogUrl="URL de la página de inicio"
      />

      {/*EJEMPLO IMPORTACIONES Y USOS DE COMPONENTES PROPIOS */}
      <NavBar />
      <Portada />
      <SummaryService />
      <Portada2 />
      <BannerSponsors />
      <SummaryProyects />
      <BannerPie />
      <Footer />
    </div>
  );
}
