import React from "react";
import "./FAQs.css";
import SEO from "../../utils/SEO";
import NavBar from "../../components/NavBar/NavBar";
import BannerSeccion from "../../components/BannerSeccion/BannerSeccion";
import FaqsImage from "../../assets/imgs/Proyects/Casa1/Estaticas/casa1_3.webp";
import Footer from "../../components/Footer/Footer";
import AccordionWithImage from "../../components/AccordionWithImage/AccordionWithImage";
import { Button } from "primereact/button";

export default function FAQs() {
  return (
    <div className="FAQs flex flex-column justify-content-start align-content-center gap-6">
      <SEO
        title="DEEC | FAQs"
        description="Encuentra respuestas a las preguntas más frecuentes sobre nuestros servicios de diseño arquitectónico y renderizado 3D. Conoce más sobre cómo trabajamos."
        keywords="FAQs, preguntas frecuentes, DEEC, diseño arquitectónico, renderizado 3D, servicios"
        author="DEEC"
        ogTitle="DEEC | Preguntas Frecuentes"
        ogDescription="Resuelve tus dudas con nuestras preguntas frecuentes sobre servicios de diseño arquitectónico y renderizado 3D."
        ogImage="URL de una imagen representativa de FAQs"
        ogUrl="URL de la página FAQs"
      />

      <NavBar />
      <BannerSeccion imageUrl={FaqsImage} title="FAQ`s" />
      <AccordionWithImage />
      <div className="grid justify-content-center align-items-center mb-6">
        <div className="flex flex-column col-12 md:col-4 gap-4">
          <div>
            <h2>Can't find an answer to your question?</h2>
          </div>
          <div>
            <Button
              label="Contact Us"
              icon="pi pi-arrow-right"
              iconPos="right"
              className="raleway-medium custom-btn-brown"
              size="small"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
