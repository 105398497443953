import React from "react";
import "./BannerSeccion.css";

const BannerSeccion = ({ imageUrl, title }) => {
  return (
    <div
      className="banner-seccion h-10rem md:h-20rem"
      style={{ backgroundImage: `url(${imageUrl})` }}
      data-aos="zoom-out"
    >
      <h1 className="raleway-bold text-over-image text-6xl">{title}</h1>
    </div>
  );
};

export default BannerSeccion;
