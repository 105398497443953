import img1 from "../../assets/imgs/Proyects/Casa1/Estaticas/casa1_1.webp";
import img2 from "../../assets/imgs/Proyects/Casa1/Estaticas/casa1_2.webp";
import img3 from "../../assets/imgs/Proyects/Casa1/Estaticas/casa1_3.webp";

import img4 from "../../assets/imgs/Proyects/Casa2/Estaticas/Casa2_1.webp";
import img5 from "../../assets/imgs/Proyects/Casa2/Estaticas/Casa2_2.webp";
import img6 from "../../assets/imgs/Proyects/Casa2/Estaticas/Casa2_3.webp";

import img7 from "../../assets/imgs/Proyects/CasaDePlaya1/360/CasaDePlaya_bedroom_4_3.webp";
import img8 from "../../assets/imgs/Proyects/CasaDePlaya1/360/CasaDePlaya_exterior_DreamVacation.webp";
import img9 from "../../assets/imgs/Proyects/CasaDePlaya1/360/CasaDePlaya_exterior_SunsetInParadise.webp";
import img10 from "../../assets/imgs/Proyects/CasaDePlaya1/360/CasaDePlaya_kitchen_dinnigroom_4_2.webp";
import img11 from "../../assets/imgs/Proyects/CasaDePlaya1/360/CasaDePlaya_living_1_4.webp";
import img112 from "../../assets/imgs/Proyects/CasaDePlaya1/Estaticas/casaPlaya1_1.png";

import img12 from "../../assets/imgs/Proyects/CasaDePlaya2/Estaticas/CasaDePlaya_1.webp";
import img13 from "../../assets/imgs/Proyects/CasaDePlaya2/Estaticas/CasaDePlaya_2.webp";
import img14 from "../../assets/imgs/Proyects/CasaDePlaya2/Estaticas/CasaDePlaya_3.webp";
import img15 from "../../assets/imgs/Proyects/CasaDePlaya2/Estaticas/CasaDePlaya_4.webp";
import img16 from "../../assets/imgs/Proyects/CasaDePlaya2/Estaticas/CasaDePlaya_5.webp";
import img17 from "../../assets/imgs/Proyects/CasaDePlaya2/Estaticas/CasaDePlaya_6.webp";

import img18 from "../../assets/imgs/Proyects/Casa_Fransworth/Estaticas/CasaFransworth_1.webp";
import img19 from "../../assets/imgs/Proyects/Casa_Fransworth/Estaticas/CasaFransworth_2.webp";
import img20 from "../../assets/imgs/Proyects/Casa_Fransworth/Estaticas/CasaFransworth_3.webp";
import img21 from "../../assets/imgs/Proyects/Casa_Fransworth/Estaticas/CasaFransworth_4.webp";

import img22 from "../../assets/imgs/Proyects/Glass/360/Glass_2_2_exterior.webp";
import img23 from "../../assets/imgs/Proyects/Glass/360/Glass_living_1_2.webp";
import img24 from "../../assets/imgs/Proyects/Glass/Estaticas/CasaDeCristal_1.webp";
import img25 from "../../assets/imgs/Proyects/Glass/Estaticas/CasaDeCristal_2.webp";
import img26 from "../../assets/imgs/Proyects/Glass/Estaticas/CasaDeCristal_3.webp";

import img27 from "../../assets/imgs/Proyects/Kaufman/360/Kaufman_exterior_Backyard.webp";
import img28 from "../../assets/imgs/Proyects/Kaufman/360/Kaufman_exterior_DaylightPool.webp";
import img29 from "../../assets/imgs/Proyects/Kaufman/360/Kaufman_exterior_PoolAtNight.webp";
import img30 from "../../assets/imgs/Proyects/Kaufman/360/Kaufman_Kitchen.webp";
import img31 from "../../assets/imgs/Proyects/Kaufman/360/Kaufman_Poolhouse.webp";
import img32 from "../../assets/imgs/Proyects/Kaufman/360/Kaufman_Terrace.webp";
import img321 from "../../assets/imgs/Proyects/Kaufman/Estaticas/kaufman_1.webp";

import img33 from "../../assets/imgs/Proyects/Museo/Estaticas/museo_1.webp";
import img34 from "../../assets/imgs/Proyects/Museo/Estaticas/museo_2.webp";

import img35 from "../../assets/imgs/Proyects/StandDubai/Estaticas/StandDEECDubai_1.webp";
import img36 from "../../assets/imgs/Proyects/StandDubai/Estaticas/StandDEECDubai_2.webp";
import img37 from "../../assets/imgs/Proyects/StandDubai/Estaticas/StandDEECDubai_3.webp";
import img38 from "../../assets/imgs/Proyects/StandDubai/Estaticas/StandDEECDubai_4.webp";
import img39 from "../../assets/imgs/Proyects/StandDubai/Estaticas/StandDEECDubai_5.webp";
import video40 from "../../assets/imgs/Proyects/StandDubai/videos/StandDubaiVideo.mp4";

import img40 from "../../assets/imgs/Proyects/TierraDorada/360/TierraDorada_exterior__sun_park1.webp";
import img41 from "../../assets/imgs/Proyects/TierraDorada/360/TierraDorada_exterior_park2.webp";
import img42 from "../../assets/imgs/Proyects/TierraDorada/360/TierraDorada_exterior_street1.webp";
import img43 from "../../assets/imgs/Proyects/TierraDorada/360/TierraDorada_exterior_street2.webp";
import img44 from "../../assets/imgs/Proyects/TierraDorada/360/TierraDorada_exterior_street5.webp";
import img45 from "../../assets/imgs/Proyects/TierraDorada/360/TierraDorada_exterior_sun_Pool.webp";
import img46 from "../../assets/imgs/Proyects/TierraDorada/360/TierraDorada_exterior_sun_street3.webp";
import img47 from "../../assets/imgs/Proyects/TierraDorada/360/TierraDorada_exterior_welcome.webp";
import img48 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_aereo_1.webp";
import img49 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_aereo_2.webp";
import img50 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_aereo_3.webp";
import img51 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_aereo_4.webp";
import img52 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_aereo_5.webp";
import img53 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_location1.webp";
import img54 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_park1_1.webp";
import img55 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_park1_2.webp";
import img56 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_park2_1.webp";
import img57 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_principalBuilding_1.webp";
import img58 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_principalBuilding_2.webp";
import img59 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_principalBuilding_3_2.webp";
import img60 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_principalBuilding_4.webp";
import img61 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_principalBuilding_5.webp";
import img62 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_principalBuilding_6.webp";
import img63 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_principalBuilding_7.webp";
import img64 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_principalBuilding_8.webp";
import img65 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_1.webp";
import img66 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_10.webp";
import img67 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_11.webp";
import img68 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_12.webp";
import img69 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_13.webp";
import img70 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_14.webp";
import img71 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_15.webp";
import img72 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_16.webp";
import img73 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_17.webp";
import img74 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_18.webp";
import img75 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_19.webp";
import img76 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_2.webp";
import img77 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_20.webp";
import img78 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_21.webp";
import img79 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_22.webp";
import img80 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_23.webp";
import img81 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_24.webp";
import img82 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_3.webp";
import img83 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_4.webp";
import img84 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_5.webp";
import img85 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_6.webp";
import img86 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_7.webp";
import img87 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_8.webp";
import img88 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_streets_9.webp";
import img89 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_welcome_1.webp";
import img90 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_welcome_3.webp";
import img91 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_welcome_4.webp";
import img92 from "../../assets/imgs/Proyects/TierraDorada/Estaticas/TierraDorada_welcome_5.webp";
import video93 from "../../assets/imgs/Proyects/TierraDorada/videos/TD2K.mp4";

const proyects = [
  {
    tag: "House",
    category: "Architecture",
    titulo_proyecto: "House 1",
    sub_titulo_proyecto: "Decor / Architecture",
    link_proyecto: "ruta/al/proyectoBR1",
    date: "20/05/24",
    client: "Client name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquamsem vitae turpis dignissim maximus. Aliquam sollicitudin tellumassa, vbel maximus purus posuere in. Dojrices gravida dignissim. Praesent at nibh in mi fringilla mattis. Phasellus ut dolor odio. Aenean in the ipsum vel lectus bibendum commodo. \n\nIn nec sem suscipit, convallis leo vitae, lacinia nibh. Cras amet tellus lectus. Vivamus ipsum nunc, mattis quis nibh id, pellentesque arcu. Donec a pellentesque Cras erat enim, gravida non ante vitae,elequis convallis elit, in viverra felis. Donec ultrices tellus vitae iaculisvd porta. Proin tincidunt ligula id purus porttitor.",
    files: {
      images360: [],
      videos: [],
      staticImages: [img1, img2, img3],
      vr: "ruta/al/vr",
    },
  },
  {
    tag: "House",
    category: "Architecture",
    titulo_proyecto: "House 2",
    sub_titulo_proyecto: "Decor / Architecture",
    link_proyecto: "ruta/al/proyectoBR2",
    date: "20/05/24",
    client: "Client name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquamsem vitae turpis dignissim maximus. Aliquam sollicitudin tellumassa, vbel maximus purus posuere in. Dojrices gravida dignissim. Praesent at nibh in mi fringilla mattis. Phasellus ut dolor odio. Aenean in the ipsum vel lectus bibendum commodo. \n\nIn nec sem suscipit, convallis leo vitae, lacinia nibh. Cras amet tellus lectus. Vivamus ipsum nunc, mattis quis nibh id, pellentesque arcu. Donec a pellentesque Cras erat enim, gravida non ante vitae,elequis convallis elit, in viverra felis. Donec ultrices tellus vitae iaculisvd porta. Proin tincidunt ligula id purus porttitor.",
    files: {
      images360: [],
      videos: [],
      staticImages: [img4, img5, img6],
      vr: "ruta/al/vr",
    },
  },
  {
    tag: "House",
    category: "Architecture",
    titulo_proyecto: "Beach House 1",
    sub_titulo_proyecto: "Decor / Architecture",
    link_proyecto: "ruta/al/proyectoBR3",
    date: "20/05/24",
    client: "Client name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquamsem vitae turpis dignissim maximus. Aliquam sollicitudin tellumassa, vbel maximus purus posuere in. Dojrices gravida dignissim. Praesent at nibh in mi fringilla mattis. Phasellus ut dolor odio. Aenean in the ipsum vel lectus bibendum commodo. \n\nIn nec sem suscipit, convallis leo vitae, lacinia nibh. Cras amet tellus lectus. Vivamus ipsum nunc, mattis quis nibh id, pellentesque arcu. Donec a pellentesque Cras erat enim, gravida non ante vitae,elequis convallis elit, in viverra felis. Donec ultrices tellus vitae iaculisvd porta. Proin tincidunt ligula id purus porttitor.",
    files: {
      images360: [img7, img8, img9, img10, img11],
      videos: [],
      staticImages: [img112],
      vr: "ruta/al/vr",
    },
  },
  {
    tag: "House",
    category: "Architecture",
    titulo_proyecto: "Beach House 2",
    sub_titulo_proyecto: "Decor / Architecture",
    link_proyecto: "ruta/al/proyectoBR4",
    date: "20/05/24",
    client: "Client name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquamsem vitae turpis dignissim maximus. Aliquam sollicitudin tellumassa, vbel maximus purus posuere in. Dojrices gravida dignissim. Praesent at nibh in mi fringilla mattis. Phasellus ut dolor odio. Aenean in the ipsum vel lectus bibendum commodo. \n\nIn nec sem suscipit, convallis leo vitae, lacinia nibh. Cras amet tellus lectus. Vivamus ipsum nunc, mattis quis nibh id, pellentesque arcu. Donec a pellentesque Cras erat enim, gravida non ante vitae,elequis convallis elit, in viverra felis. Donec ultrices tellus vitae iaculisvd porta. Proin tincidunt ligula id purus porttitor.",
    files: {
      images360: [],
      videos: [],
      staticImages: [img12, img13, img14, img15, img16, img17],
      vr: "ruta/al/vr",
    },
  },
  {
    tag: "House",
    category: "Architecture",
    titulo_proyecto: "Fransworth House",
    sub_titulo_proyecto: "Decor / Architecture",
    link_proyecto: "ruta/al/proyectoBR5",
    date: "20/05/24",
    client: "Client name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquamsem vitae turpis dignissim maximus. Aliquam sollicitudin tellumassa, vbel maximus purus posuere in. Dojrices gravida dignissim. Praesent at nibh in mi fringilla mattis. Phasellus ut dolor odio. Aenean in the ipsum vel lectus bibendum commodo. \n\nIn nec sem suscipit, convallis leo vitae, lacinia nibh. Cras amet tellus lectus. Vivamus ipsum nunc, mattis quis nibh id, pellentesque arcu. Donec a pellentesque Cras erat enim, gravida non ante vitae,elequis convallis elit, in viverra felis. Donec ultrices tellus vitae iaculisvd porta. Proin tincidunt ligula id purus porttitor.",
    files: {
      images360: [],
      videos: [],
      staticImages: [img18, img19, img20, img21],
      vr: "ruta/al/vr",
    },
  },
  {
    tag: "House",
    category: "Architecture",
    titulo_proyecto: "Glass House",
    sub_titulo_proyecto: "Decor / Architecture",
    link_proyecto: "ruta/al/proyectoBR6",
    date: "20/05/24",
    client: "Client name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquamsem vitae turpis dignissim maximus. Aliquam sollicitudin tellumassa, vbel maximus purus posuere in. Dojrices gravida dignissim. Praesent at nibh in mi fringilla mattis. Phasellus ut dolor odio. Aenean in the ipsum vel lectus bibendum commodo. \n\nIn nec sem suscipit, convallis leo vitae, lacinia nibh. Cras amet tellus lectus. Vivamus ipsum nunc, mattis quis nibh id, pellentesque arcu. Donec a pellentesque Cras erat enim, gravida non ante vitae,elequis convallis elit, in viverra felis. Donec ultrices tellus vitae iaculisvd porta. Proin tincidunt ligula id purus porttitor.",
    files: {
      images360: [img22, img23],
      videos: [],
      staticImages: [img24, img25, img26],
      vr: "ruta/al/vr",
    },
  },
  {
    tag: "House",
    category: "Architecture",
    titulo_proyecto: "Kaufman House",
    sub_titulo_proyecto: "Decor / Architecture",
    link_proyecto: "ruta/al/proyectoBR7",
    date: "20/05/24",
    client: "Client name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquamsem vitae turpis dignissim maximus. Aliquam sollicitudin tellumassa, vbel maximus purus posuere in. Dojrices gravida dignissim. Praesent at nibh in mi fringilla mattis. Phasellus ut dolor odio. Aenean in the ipsum vel lectus bibendum commodo. \n\nIn nec sem suscipit, convallis leo vitae, lacinia nibh. Cras amet tellus lectus. Vivamus ipsum nunc, mattis quis nibh id, pellentesque arcu. Donec a pellentesque Cras erat enim, gravida non ante vitae,elequis convallis elit, in viverra felis. Donec ultrices tellus vitae iaculisvd porta. Proin tincidunt ligula id purus porttitor.",
    files: {
      images360: [img27, img28, img29, img30, img31, img32],
      videos: [],
      staticImages: [img321],
      vr: "ruta/al/vr",
    },
  },
  {
    tag: "Spaces",
    category: "Architecture",
    titulo_proyecto: "Museo",
    sub_titulo_proyecto: "Decor / Architecture",
    link_proyecto: "ruta/al/proyectoBR8",
    date: "20/05/24",
    client: "Client name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquamsem vitae turpis dignissim maximus. Aliquam sollicitudin tellumassa, vbel maximus purus posuere in. Dojrices gravida dignissim. Praesent at nibh in mi fringilla mattis. Phasellus ut dolor odio. Aenean in the ipsum vel lectus bibendum commodo. \n\nIn nec sem suscipit, convallis leo vitae, lacinia nibh. Cras amet tellus lectus. Vivamus ipsum nunc, mattis quis nibh id, pellentesque arcu. Donec a pellentesque Cras erat enim, gravida non ante vitae,elequis convallis elit, in viverra felis. Donec ultrices tellus vitae iaculisvd porta. Proin tincidunt ligula id purus porttitor.",
    files: {
      images360: [],
      videos: [],
      staticImages: [img33, img34],
      vr: "ruta/al/vr",
    },
  },
  {
    tag: "Spaces",
    category: "Architecture",
    titulo_proyecto: "Stand Dubai",
    sub_titulo_proyecto: "Decor / Architecture",
    link_proyecto: "ruta/al/proyectoBR9",
    date: "20/05/24",
    client: "Client name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquamsem vitae turpis dignissim maximus. Aliquam sollicitudin tellumassa, vbel maximus purus posuere in. Dojrices gravida dignissim. Praesent at nibh in mi fringilla mattis. Phasellus ut dolor odio. Aenean in the ipsum vel lectus bibendum commodo. \n\nIn nec sem suscipit, convallis leo vitae, lacinia nibh. Cras amet tellus lectus. Vivamus ipsum nunc, mattis quis nibh id, pellentesque arcu. Donec a pellentesque Cras erat enim, gravida non ante vitae,elequis convallis elit, in viverra felis. Donec ultrices tellus vitae iaculisvd porta. Proin tincidunt ligula id purus porttitor.",
    files: {
      images360: [],
      videos: [video40],
      staticImages: [img35, img36, img37, img38, img39],
      vr: "ruta/al/vr",
    },
  },
  {
    tag: "Master Planning",
    category: "Architecture",
    titulo_proyecto: "Tierra Dorada",
    sub_titulo_proyecto: "Decor / Architecture",
    link_proyecto: "ruta/al/proyectoBR10",
    date: "20/05/24",
    client: "Client name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquamsem vitae turpis dignissim maximus. Aliquam sollicitudin tellumassa, vbel maximus purus posuere in. Dojrices gravida dignissim. Praesent at nibh in mi fringilla mattis. Phasellus ut dolor odio. Aenean in the ipsum vel lectus bibendum commodo. \n\nIn nec sem suscipit, convallis leo vitae, lacinia nibh. Cras amet tellus lectus. Vivamus ipsum nunc, mattis quis nibh id, pellentesque arcu. Donec a pellentesque Cras erat enim, gravida non ante vitae,elequis convallis elit, in viverra felis. Donec ultrices tellus vitae iaculisvd porta. Proin tincidunt ligula id purus porttitor.",
    files: {
      images360: [img40, img41, img42, img43, img44, img45, img46, img47],
      videos: [video93],
      staticImages: [
        img48,
        img49,
        img50,
        img51,
        img52,
        img53,
        img54,
        img55,
        img56,
        img57,
        img58,
        img59,
        img60,
        img61,
        img62,
        img63,
        img64,
        img65,
        img66,
        img67,
        img68,
        img69,
        img70,
        img71,
        img72,
        img73,
        img74,
        img75,
        img76,
        img77,
        img78,
        img79,
        img80,
        img81,
        img82,
        img83,
        img84,
        img85,
        img86,
        img87,
        img88,
        img89,
        img90,
        img91,
        img92,
      ],
      vr: "ruta/al/vr",
    },
  },
];

export default proyects;
