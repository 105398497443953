import React from "react";
import "./Portada.css";

export default function Portada() {
  return (
    <div className="grid grid-nogutter justify-content-center">
      <div
        className="flex justify-content-start w-10 h-25rem md:w-8 md:h-30rem container-image custom-image"
        data-aos="zoom-out"
      ></div>
    </div>
  );
}
