import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Card } from "primereact/card";
import { faqs } from "../../utils/data/faqs";
import "./AccordionWithImage.css";

const AccordionWithImage = () => {
  return (
    <div className="grid grid-nogutter col-12 justify-content-center m-0 p-0">
      <div
        className="flex flex-column w-full md:w-10 justify-content-center align-items-center gap-3 m-4"
        data-aos="fade-up"
      >
        {faqs.map((faq, index) => (
          <div key={index} className="w-full p-0 m-0">
            <div>
              <h1>{faq.title}</h1>
            </div>
            <Card className="p-4">
              <div
                className={`grid flex-column align-items-center justify-content-center gap-4 ${
                  index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                }`}
              >
                {/* Sección de imagen */}
                <div className="w-full md:w-4 faqImage flex justify-content-center">
                  <div className="faq-image-container w-full h-25rem">
                    <img
                      src={faq.image}
                      alt={`Imagen ${index + 1}`}
                      className="image w-full h-full object-cover"
                    />
                  </div>
                </div>

                {/* Sección de contenido */}
                <div className="w-full md:w-6 m-0 p-3 md:p-0">
                  <Accordion className="text-left">
                    {faq.faqs.map((faq, faqIndex) => (
                      <AccordionTab
                        key={faqIndex}
                        header={
                          <span className="raleway-semibold">
                            {faq.question}
                          </span>
                        }
                      >
                        <p className="raleway-regular text-xs">{faq.answer}</p>
                      </AccordionTab>
                    ))}
                  </Accordion>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccordionWithImage;
