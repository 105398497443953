import React from "react";
import "./CardProyect.css";

/* PRIMER REACT */
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

export default function CardProyect({ proyecto }) {
  const navigate = useNavigate();

  const handleReadMore = () => {
    navigate("/proyect-details", { state: { proyecto } });
  };
  return (
    <div className="CardProyect" data-aos="fade-up">
      <div
        className="h-30rem w-full border-round CardProyectContainer"
        style={{ backgroundImage: `url(${proyecto.files.staticImages[0]})` }}
      ></div>

      <div className="flex justify-content-between w-full my-2">
        <div>
          <h3 className="raleway-semibold m-0 text-left">
            {proyecto.titulo_proyecto}
          </h3>
          <h4 className="raleway-regular m-0 text-left">
            {proyecto.sub_titulo_proyecto}
          </h4>
        </div>
        <Button
          rounded
          size="small"
          icon="pi text-2xl pi-angle-right"
          aria-label="Filter"
          className="custom-btn-brown"
          onClick={handleReadMore}
        />
      </div>
    </div>
  );
}
