import React from "react";
import "./BannerPie.css";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

export default function BannerPie() {
  const navigate = useNavigate();

  const handleNavegate = () => {
    navigate("/contact-us");
  };

  return (
    <div className="flex justify-content-center w-full h-14rem md:w-12 md:h-18rem container-image4">
      <div className="align-content-center text-over-image">
        <h2 className="raleway-bold text-2xl md:text-4xl">
          Wanna join the interno?
        </h2>
        <p className="raleway-medium">
          Be part of our journey. Let’s bring your vision to life with
          creativity and innovation.
        </p>
        <Button
          label="Contact with us"
          icon="pi pi-arrow-right"
          iconPos="right"
          size="small"
          className="raleway-semibold custom-btn-brown"
          onClick={handleNavegate}
        />
      </div>
    </div>
  );
}
