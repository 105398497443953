import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FloatLabel } from 'primereact/floatlabel';
import { Button } from 'primereact/button';
import telicon from "../../assets/imgs/varios/telicon.png";
import mail from "../../assets/imgs/varios/mail.png";
import Social from "../../components/Social/Social";

const ContactFormLargo = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const createMailtoLink = () => {
    const { name, email, subject, phone, message } = formData;
    const mailto = `mailto:deecvrdesign@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\n\nMessage:\n${message}`
    )}`;
    return mailto;
  };

  return (
    <div className="grid grid-nogutter justify-content-center m-0 p-0">
      <div className="flex flex-column w-10 lg:w-10 xl:w-8 justify-content-center align-items-center gap-6 mb-4">
        <div className="w-full md:w-8">
          <h2 className="text-center raleway-bold">
            We love meeting new people and helping them.
          </h2>
        </div>

        <div className="flex md:flex-row flex-column justify-content-start md:justify-content-center align-items-start gap-6 md:gap-2 p-2 m-2 w-full">
          <div className="flex flex-column gap-3 text-sm w-full md:w-6 p-0">
            <div className="flex flex-row gap-3 p-2">
              <img src={telicon} alt="teléfono" className="icono" />
              <div className="flex flex-column justify-content-center">
                <p className="m-0 raleway-medium">
                  +54 2477 201703 <span className="fi fi-ar"></span>
                </p>
                <p className="m-0 raleway-medium">
                  +971 58 191 1703 <span className="fi fi-ae"></span>
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-3 p-2">
              <img src={mail} alt="email" className="icono" />
              <div className="flex flex-column justify-content-center">
                <p className="m-0 raleway-medium">deecvrdesign@gmail.com</p>
              </div>
            </div>
            <Social />
          </div>
          {/* Formulario */}
          <div className="flex flex-column gap-6 w-full md:w-10 jost-400 text-sm p-0">
            <div className="flex flex-column md:flex-row gap-6 md:gap-4">
              <FloatLabel className="flex-grow-1">
                <InputText
                  id="name"
                  autoComplete="off"
                  className="raleway-regular input-custom p-0"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <label htmlFor="name">Name</label>
              </FloatLabel>
              <FloatLabel className="flex-grow-1">
                <InputText
                  id="email"
                  autoComplete="off"
                  className="raleway-regular input-custom p-0"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <label htmlFor="email">Email</label>
              </FloatLabel>
            </div>

            <div className="flex flex-column md:flex-row gap-6 md:gap-4">
              <FloatLabel className="flex-grow-1">
                <InputText
                  id="subject"
                  autoComplete="off"
                  className="raleway-regular input-custom p-0"
                  value={formData.subject}
                  onChange={handleInputChange}
                />
                <label htmlFor="subject">Subject</label>
              </FloatLabel>
              <FloatLabel className="flex-grow-1">
                <InputText
                  id="phone"
                  autoComplete="phone"
                  className="raleway-regular input-custom p-0"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                <label htmlFor="phone">Phone</label>
              </FloatLabel>
            </div>

            <FloatLabel>
              <InputTextarea
                id="message"
                autoResize
                rows={1}
                className="raleway-regular input-custom p-1"
                value={formData.message}
                onChange={handleInputChange}
              />
              <label htmlFor="message">Hello, I am interested in...</label>
            </FloatLabel>
          </div>
        </div>

        <a href={createMailtoLink()}>
          <Button
            label="Send now"
            icon="pi pi-arrow-right"
            iconPos="right"
            size="small"
            className="raleway-medium custom-btn-brown"
          />
        </a>
      </div>
    </div>
  );
};

export default ContactFormLargo;
