import React, { useState, useEffect } from "react";
import "./SummaryProyects.css";

/* COMPONENTS */
import CardTemplate from "./CardTemplate/CardTemplate";

/* PRIME REACT */
import { Carousel } from "primereact/carousel";

/* HOOKS */
import { useWindowResize } from "../../utils/hooks/useWindowResize";

/* JSON */
import proyectos from "../../utils/data/proyects.js";

export default function SummaryProyects() {
  useWindowResize();

  const [proyectosAleatorios, setProyectosAleatorios] = useState([]);

  useEffect(() => {
    const shuffleAndSliceArray = (array, size) => {
      const shuffled = array.sort(() => Math.random() - 0.5);
      return shuffled.slice(0, size);
    };

    const proyectosShuffled = shuffleAndSliceArray(
      [...proyectos],
      proyectos.length > 8 ? 8 : proyectos.length
    );
    setProyectosAleatorios(proyectosShuffled);
  }, []);

  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  return (
    <div className="SummaryProyects grid grid-nogutter neste-grid justify-content-center gap-6">
      <div className="col-12 flex justify-content-center">
        <div className="w-11 md:w-5" data-aos="fade-up">
          <h1 className="raleway-bold sm:text-2xl md:text-5xl">
            Follow Our Projects
          </h1>
          <p className="raleway-regular">
            Explore our portfolio of unique designs and see how we’ve turned
            visions into reality for our clients.
          </p>
        </div>
      </div>

      <div className="col-10 md:col-8" data-aos="fade-up">
        <Carousel
          value={proyectosAleatorios}
          numVisible={3}
          numScroll={3}
          responsiveOptions={responsiveOptions}
          itemTemplate={(proyecto) => <CardTemplate proyecto={proyecto} />}
          circular={false}
          showNavigators={false}
          autoplayInterval={5000}
        />
      </div>
    </div>
  );
}
