import React from "react";
import "./Portada2.css";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import telicon from "../../assets/imgs/varios/telicon.png";

export default function Portada2() {
  const navigate = useNavigate();

  const handleNavegate = () => {
    navigate("/contact-us");
  };

  return (
    <div
      className="grid grid-nogutter justify-content-center"
      data-aos="fade-up"
    >
      <div className="flex justify-content-start align-items-center w-10 h-30rem md:w-8 md:h-30rem container-image2 custom-image-2">
        <div className="w-10 h-25rem md:w-5 md:h-25rem text-left text-over-image align-content-center p-2 ml-3 border-round container-info-portada-2">
          <h2 className="raleway-bold text-2xl md:text-3xl">
            We create art for modern life
          </h2>
          <p className="raleway-medium">
            Transform your spaces with cutting-edge designs that reflect
            contemporary lifestyles.
          </p>
          <div className="flex justify-content-start align-items-center my-3 column-gap-3">
            <img src={telicon} alt="teléfono" className="icono" />
            <div className="flex flex-column justify-content-around align-content-start">
              <p className="m-0 raleway-medium">+54 2477 201703</p>
              <p className="m-0 raleway-medium">+971 58 191 1703</p>
              <p className="m-0 raleway-medium">Call Us Anytime</p>
            </div>
          </div>
          <Button
            label="Get Free Estimated"
            icon="pi pi-arrow-right"
            iconPos="right"
            size="small"
            className="raleway-medium custom-btn-brown"
            onClick={handleNavegate}
          />
        </div>
      </div>
    </div>
  );
}
