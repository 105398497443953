// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Carrousel.css";

import carrouselImages from "../../utils/data/carrouselImages";

export default function Carrousel() {
  return (
    <>
      <div className="seccion flex w-full h-20rem justify-content-center align-items-center m-0">
        <h1 className="raleway-bold text-6xl text-center text-over-image m-0">
          About Us
        </h1>
      </div>
      <swiper-container
        data-aos="zoom-out"
        className="mySwiper"
        navigation="true"
        pagination="true"
        effect="coverflow"
        grab-cursor="true"
        centered-slides="true"
        slides-per-view="auto"
        autoplay='{"delay": 5000}'
        speed="500"
        loop="true"
        coverflow-effect-rotate="30"
        coverflow-effect-stretch="100"
        coverflow-effect-scale="1"
        coverflow-effect-depth="100"
        coverflow-effect-modifier="1"
        coverflow-effect-slide-shadows="true"
        breakpoints='{
          "0": {
            "slidesPerView": 1,
            "coverflowEffect": {
              "stretch": 50,
              "depth": 50
            }
          },
          "640": {
            "slidesPerView": 2,
            "coverflowEffect": {
              "stretch": 50,
              "depth": 50
            }
          },
          "768": {
            "slidesPerView": 2,
            "coverflowEffect": {
              "stretch": 75,
              "depth": 75
            }
          },
          "1024": {
            "slidesPerView": "auto",
            "coverflowEffect": {
              "stretch": 100,
              "depth": 100
            }
          }
        }'
      >
        {carrouselImages.map((image, index) => (
          <swiper-slide key={index}>
            <img src={image.src} alt={image.alt} />
          </swiper-slide>
        ))}
      </swiper-container>
    </>
  );
}
