import React from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import "./App.css";

/* PRIME REACT */
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

/* PAGES */
import Home from "./sections/Home/Home";
import AboutUs from "./sections/AboutUs/AboutUs";
import Services from "./sections/Services/Services";
import SingleServices from "./sections/SingleServices/SingleServices";
import Proyects from "./sections/Proyects/Proyects";
import ContactUs from "./sections/ContactUs/ContactUs";
import FAQs from "./sections/FAQs/FAQs";
import ProyectDetails from "./sections/ProyectDetails/ProyectDetails";

/* HOOKS */
import usePageVisibility from "./utils/hooks/usePageVisibility";

function App() {
  usePageVisibility("DEEC | ¡Continuemos trabajando!");

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/single-service" element={<SingleServices />} />
        <Route path="/proyect" element={<Proyects />} />
        <Route path="/proyect-details" element={<ProyectDetails />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/faqs" element={<FAQs />} />
      </Routes>
    </div>
  );
}

export default App;
