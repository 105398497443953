import React from "react";
import "./IntroductionAboutUs.css";

export default function IntroductionAboutUst() {
  return (
    <div className="IntroductionAboutUst grid grid-nogutter justify-content-center align-content-center w-full h-15rem">
      <div className="col-10 md:col-4 border-left-1 border-right-1 bloque">
        <h2 className="text-center raleway-medium">
          I like an interior that defies labeling. I don't really want someone
          to walk into a room and know that I did it
        </h2>
        <p className="uppercase raleway-light">BUNNY WILLIAMS</p>
      </div>
    </div>
  );
}
